import posthog from "posthog-js";
import { useEffect, useState } from "react";
import { Button, LinkButton } from "./primitives/Buttons";
import { Paragraph } from "./primitives/text/Paragraph";

export default function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false);
  const posthogOptIn = posthog.has_opted_in_capturing();
  const posthogOptOut = posthog.has_opted_out_capturing();

  useEffect(() => {
    if (posthogOptIn || posthogOptOut) {
      setShowBanner(false); // Hide the banner if the user has made a choice
    } else {
      setShowBanner(true); // Show the banner if no choice has been made
    }
  }, [posthogOptIn, posthogOptOut]);

  const acceptCookies = () => {
    posthog.opt_in_capturing();
    setShowBanner(false);
  };

  const declineCookies = () => {
    posthog.opt_out_capturing();
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className="fixed bottom-0 left-0 z-40 m-3 w-[calc(100%-1.5rem)] rounded-md border border-grid-bright bg-grid-dimmed p-4 shadow-lg sm:w-80">
          <Paragraph variant={"small"} className="p-2">
            We only collect analytics cookies so we can improve your experience.
          </Paragraph>
          <div className="flex place-content-between space-x-2 pt-1.5">
            <LinkButton
              variant={"minimal/small"}
              to="/legal/privacy"
              noBackground
              textClassName="text-charcoal-300 hover:text-charcoal-200"
            >
              Privacy policy
            </LinkButton>
            <div className="flex gap-2">
              <Button
                variant={"minimal/small"}
                type="button"
                onClick={declineCookies}
                textClassName="text-charcoal-300 hover:text-charcoal-200"
              >
                Opt-out
              </Button>
              <Button
                type="button"
                onClick={acceptCookies}
                variant={"tertiary/small"}
                className="bg-grid-bright transition duration-200 hover:bg-charcoal-600"
              >
                Accept
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
