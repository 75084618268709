import { useLocation } from "@remix-run/react";
import posthog from "posthog-js";
import { useEffect, useRef } from "react";
export const usePostHog = (apiKey?: string, logging = false): void => {
  const postHogInitialized = useRef(false);
  const location = useLocation();
  //start PostHog once
  useEffect(() => {
    if (apiKey === undefined) return;
    if (postHogInitialized.current === true) return;
    if (logging) console.log("posthog.init", apiKey);
    postHogInitialized.current = true;
    posthog.init(apiKey, {
      api_host: "https://eu.posthog.com",
      opt_in_site_apps: true,
      loaded: function (posthog) {
        if (logging) console.log("posthog.loaded", apiKey);
      },
    });
  }, [apiKey, logging]);
  //page view
  useEffect(() => {
    if (postHogInitialized.current === false) return;
    if (logging) console.log("posthog.capture", "$pageview", location.pathname);
    posthog.capture("$pageview");
  }, [location, logging]);
};
