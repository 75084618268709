import codeHikeStylesheetUrl from "@code-hike/mdx/dist/index.css";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError } from
"@remix-run/react";
import {
  type LinksFunction,
  type LoaderArgs,
  type V2_MetaFunction } from
"@vercel/remix";
import { motion } from "framer-motion";
import {
  typedjson,
  useTypedLoaderData,
  useTypedRouteLoaderData } from
"remix-typedjson";
import { useScramble } from "use-scramble";
import { usePostHog } from "~/hooks/usePostHog";
import tailwindStylesheet from "~/tailwind.css";
import CookieBanner from "./components/CookieBanner";
import { LinkButton } from "./components/primitives/Buttons";
import { GridContent, GridRow } from "./components/primitives/GridRow";
import { Paragraph } from "./components/primitives/text/Paragraph";
import ogImagePath from "./images/og-images/og-image.jpg";
import { getBlogPosts } from "./routes/blog/posts.server";
import { getChangelogs } from "./routes/changelog/changelog.server";
import { getServerMemberCount } from "./utils/discordBot.server";
import { description, image, title, twitter, url } from "./utils/meta";
import { getStarCount } from "./utils/starcount.server";

export const loader = async ({ request }: LoaderArgs) => {
  const url = new URL(request.url);
  const origin = url.origin;

  const posthogProjectKey = process.env.POSTHOG_PROJECT_KEY;

  const stargazers = await getStarCount();

  const discordMemberCount = await getServerMemberCount();

  const posts = getBlogPosts();
  const changelogs = getChangelogs();

  return typedjson({
    origin,
    posthogProjectKey,
    stargazers,
    discordMemberCount,
    latestBlogPosts: posts.
    filter(
      (post) => post.hideFromFeed === undefined || post.hideFromFeed === false
    ).
    slice(0, 3),
    latestChangelogs: changelogs.slice(0, 3)
  });
};

export function useStarCount() {
  const data = useTypedRouteLoaderData<typeof loader>("root");
  return data?.stargazers ?? 8000;
}

export function useDiscordMemberCount() {
  const data = useTypedRouteLoaderData<typeof loader>("root");
  return data?.discordMemberCount ?? 1700;
}

export function useLatestBlogPosts() {
  const data = useTypedRouteLoaderData<typeof loader>("root");
  return data?.latestBlogPosts ?? [];
}

export function useLatestChangelogs() {
  const data = useTypedRouteLoaderData<typeof loader>("root");
  return data?.latestChangelogs ?? [];
}

export const links: LinksFunction = () => [
{ rel: "stylesheet", href: tailwindStylesheet },
{ rel: "stylesheet", href: codeHikeStylesheetUrl }];


export const meta: V2_MetaFunction = ({ data, matches }) => {
  return [
  ...title("Trigger.dev | Open source background jobs with no timeouts."),
  ...description(
    "Build and deploy reliable background jobs with no timeouts and no infrastructure to manage."
  ),
  ...image({ matches, path: ogImagePath }),
  ...url(matches, ""),
  ...twitter()];

};

export default function App() {
  const { posthogProjectKey } = useTypedLoaderData<typeof loader>();

  usePostHog(posthogProjectKey);
  return (
    <html
      lang="en"
      className="scroll-smooth"
      style={{
        colorScheme: "dark"
      }}>

      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="grid h-full min-h-screen w-full max-w-[100vw] grid-rows-[auto,1fr,auto] overflow-x-hidden text-bright">
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
        {posthogProjectKey && <CookieBanner />}
      </body>
    </html>);

}

export function ErrorBoundary() {
  const error = useRouteError();

  let errorMessage = "";
  let errorDescription = "";

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    errorMessage = `${error.status} ${error.statusText}`;
    errorDescription = error.data.message;
  } else {
    errorMessage = "Unknown error";
    if (error instanceof Error) {
      errorMessage = error.message;
    }
  }

  const combinedText = `${errorMessage}${
  errorDescription ? ` ${errorDescription}!` : ""
  }`;

  const { ref } = useScramble({
    text: combinedText,
    speed: 0.5,
    tick: 1,
    step: 1,
    scramble: 4,
    seed: 0
  });

  return (
    <html>
      <head>
        <title>Oops! {errorMessage}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div className="h-dvh w-full bg-charcoal-1000">
          <GridRow className="h-full">
            <GridContent
              defaultColSpans
              className="flex items-center justify-start gap-4 ">

              <div className="flex flex-col items-start gap-1">
                <Paragraph variant={"large"} className="font-mono">
                  <span className="text-apple-500">Trigger</span>
                  <span className="text-lavender-500">.dev</span>
                </Paragraph>
                <Paragraph
                  variant={"large"}
                  className="font-mono text-charcoal-600">

                  -----------
                </Paragraph>

                <Paragraph variant={"large"} className="font-mono">
                  Sorry :( ! This page doesn't exist...
                </Paragraph>
                <Paragraph variant={"large"} className="font-mono">
                  <span ref={ref} />
                </Paragraph>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    delay: 0.5,
                    duration: 1,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut"
                  }}
                  className="block">

                  <LinkButton
                    variant="secondary/large"
                    to={"/"}
                    className="mx-0 border-0 bg-background/0 px-0 font-mono text-lavender-500"
                    shortcut={{
                      key: "enter"
                    }}>

                    Go back?
                  </LinkButton>
                </motion.div>
              </div>
              <Scripts />
            </GridContent>
          </GridRow>
        </div>
      </body>
    </html>);

}